import React from 'react';
import Head from "next/head";
import {Helpers, Providers, Styles,} from "@tpd-web-common-libs/nodejs-library";
import {getEnv} from '../../config/deployManagement';
import ENV_CONFIG from '../../config/envConfig';
import { UtagScript } from './script-tags/scripts/UtagScript';
import { UtagConfigScript } from './script-tags/scripts/UtagConfigScript';


export default function HeadTags(props: Providers.PageLayouts.TNextPage) {
 
  const { title, description, head = {}, properties = {} } = props;

  const headEntries = Object.entries(head).sort(
    ([, v1], [, v2]) => v1.order - v2.order
  );

  const placements = props.placements ?? [];
  const placementArray = Array.isArray(placements) ? placements : [placements];
                    
  const isUtagSyncEnabled = placementArray.some((placement)=> placement.component === 'tpdAdobeTargetContainer')

  const getUtagSyncEnv = () => {

    if (ENV_CONFIG.ENV_NAME === ENV_CONFIG.ALL_ENV_NAME_TYPES.LOCAL_PRODUCTION) {
      return 'dev';
    }
    
    const env = getEnv();
    switch (env) {
      case 'dev':
      case 'evo-dev':
        return 'dev';
      case 'sit':
      case 'evo-sit':
      case 'qa':
        return 'qa';
      case 'prod':
        return 'prod';
      default:
        return 'prod';
    }
  }

  const headTags = headEntries.map((headEntry) => {
    const [headKey, headValue] = headEntry;
    const { type, value, ...headProps } = headValue;

    switch (type) {
      case Providers.PageLayouts.HEAD_TYPES.STYLE:
        return (
          <style
            key={`style-${headKey}`}
            suppressHydrationWarning
            dangerouslySetInnerHTML={{ __html: value }}
          ></style>
        );

      case Providers.PageLayouts.HEAD_TYPES.LINK:
        return (
          <link
            {...headProps}
            key={`link-${headKey}`}
            rel="stylesheet"
            href={value}
          ></link>
        );

      case Providers.PageLayouts.HEAD_TYPES.META:
        return <meta {...headProps} key={`meta-${headKey}`} content={value} />;

      case Providers.PageLayouts.HEAD_TYPES.TITLE:
        return (
          <title {...headProps} key={`title-${headKey}`}>
            {value}
          </title>
        );

      default:
        return null;
    }
  });

  const metaTitle = properties["meta-title"] || title;
  const metaDescription = properties["meta-description"] || description;
  const relCanonical =
    properties["rel-canonical"] === "/"
      ? `https://www.${Helpers.BrandNameHelper.urlName()}.it`
      : properties["rel-canonical"];

  const robotsContent = Helpers.PageHelper.getRobotsContent(props);

  return (
      <Head>
        <UtagConfigScript/>
        {metaTitle && <title key="head-title">{metaTitle}</title>}
        <link
            href="/NextAssets/icons/favicon/favicon.ico"
            rel="shortcut icon"
            type="image/x-icon"
        />
        {properties["rel-canonical"] && (
            <link rel="canonical" href={relCanonical}/>
        )}
        {metaTitle && (
            <meta property="og:title" content={metaTitle} key="head-og-title"/>
        )}
        {metaDescription && (
            <meta
                name="description"
                content={metaDescription}
                key="head-description"
            />
        )}
        <meta name="robots" key="head-robots" content={robotsContent}/>
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
        <link rel="manifest" href="/manifest.json"/>
        <meta
            name="theme-color"
            content={Styles.Themes.UnipolColors.blueOne}
        ></meta>
        {headTags}

        <meta name="facebook-domain-verification" content="1aue0p5zetvudpotr6l8kcbtv1hnll"/>
        <meta name="google-site-verification" content="06rSArAor_2E6qZuiVqpFYk1_YylbvCLNZBbJ2IDTMY"/>

        {isUtagSyncEnabled &&
            (<script src={`https://tags.tiqcdn.com/utag/unipolsai/unipolsai.it/${getUtagSyncEnv()}/utag.sync.js`}
                     type="text/javascript"/>)}
        <UtagScript/>
      </Head>
  );
}
