import React from 'react';
import { Helpers, Providers } from "@tpd-web-common-libs/nodejs-library";

import dynamic from "next/dynamic";
import LazyHydrate from "react-lazy-hydration";

const Remote = dynamic(() => import("../Remote/Remote"));

export type TNextPlacementProps = {
  placement: Providers.PageLayouts.CmsPage.TNextPlacement;
} & Omit<Providers.PageLayouts.CmsPage.TNextPageLayout, "placements">;

export const CMSPlacement = (props: TNextPlacementProps) => {
  const {
    placement: { component: __mfeName__, content },
  } = props;

  const componentProps = {
    ...(content as Providers.PageLayouts.CmsPage.TNextContentSuccess),
    __mfeName__,
  };

  const noHydratationComponents = [
    "tpdRichTextConSfondo",
    "tpdAreeDiGaranzia",
    "tpdCtaAreaRiservata",
    "tpdNuovoLancioPreventivatori",
  ];

  const eagerHydratationComponents = [
    "tpdPreventivatoreStickyContainer",
    "tpdAdobeTargetContainer"
  ];

  const disableHydratation =
    Helpers.EnvironmentHelper.isClientSide() &&
    (navigator.userAgent.includes("Google PageSpeed Insights") ||
      navigator.userAgent.includes("Chrome-Lighthouse") ||
      noHydratationComponents.includes(componentProps.__mfeName__));

  return (
    !eagerHydratationComponents.includes(componentProps.__mfeName__) ?
      (<LazyHydrate ssrOnly={disableHydratation} whenVisible={!disableHydratation}>
        <Remote
          componentProps={Helpers.RemoteHelper.getComponentPropsBy(
            componentProps,
            props
          )}
        />
      </LazyHydrate> ):
        (<Remote
          componentProps={Helpers.RemoteHelper.getComponentPropsBy(
            componentProps,
            props
          )}
        />)
  );
};
