import React from 'react';
import { Helpers, Providers } from "@tpd-web-common-libs/nodejs-library";
import PageLayout from "../page-layouts/PageLayout";
import EditModeToggle from "./EditModeToggle";
import HeadTags from "./HeadTags";
import { EndingBodyScripts } from "./script-tags/EndingBodyScripts";
import { useEffect } from "react";
import { PageDataLayer } from "./PageDataLayer";

export default function RemotePage(props: Providers.PageLayouts.TNextPage) {
  useEffect(() => {
    const routerService = Providers.RouterService.getInstance();
    routerService.onRouteChangeComplete(props);

    return () => {
      // do nothing
    };
  }, [props.url]);

  return (
    <div className="mfe-page-container">
      <HeadTags {...props} />
      <PageDataLayer />
      <PageLayout {...props} />
      <EndingBodyScripts />
      {Helpers.EnvironmentHelper.isLocalEnvironment() && <EditModeToggle />}
    </div>
  );
}
