import React from 'react';
import { Helpers, Providers } from "@tpd-web-common-libs/nodejs-library";
import { useState } from "react";

export function PageDataLayer() {
  const [layer] = useState(async () => {
    const userCrmIdStorage = await Helpers.SessionHelper.getData(
      "user_crm_id"
    ).catch(() => null);

    const dataLayer = {
      user_crm_id: userCrmIdStorage,
    };

    const analyticsService = Providers.AnalyticsService.getInstance();
    analyticsService.updateSourceDataLayer(dataLayer);

    return dataLayer;
  });

  return (
    <div
      className={Helpers.AnalyticsHelper.SOURCE_DATA_LAYER_CLASS}
      data-datalayer={Helpers.AnalyticsHelper.stringifyDataLayer(layer)}
    />
  );
}
