import React from 'react';
import { Providers, Helpers } from "@tpd-web-common-libs/nodejs-library";
import { CMSPlacement } from "./CMSPlacement";

export const CMSPlacementsMap = (
  props: Providers.PageLayouts.CmsPage.TNextPageLayout
) => {
  const { placements, ...pageProps } = props;

  return placements.reduce(
    (acc, placement: Providers.PageLayouts.CmsPage.TNextPlacement) => {
      const slotName = Helpers.normalizeKey(placement.slot);

      if (slotName in acc) {
        if (Array.isArray(acc[slotName])) {
          return {
            ...acc,
            [slotName]: [
              ...acc[slotName],
              <CMSPlacement {...pageProps} placement={placement} key={placement.component + "array-placement"} />,
            ],
          };
        } else {
          return {
            ...acc,
            [slotName]: [
              acc[slotName],
              <CMSPlacement {...pageProps} placement={placement}  key={placement.component + "placement"}/>,
            ],
          };
        }
      } else {
        return {
          ...acc,
          [slotName]: <CMSPlacement {...pageProps} placement={placement} />,
        };
      }
    },
    {}
  );
};
