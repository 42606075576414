import React from 'react';
import Remote from "./__shared__/Remote/Remote";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import CMSTemplate from "./__shared__/CMSTemplate";
import { Providers } from "@tpd-web-common-libs/nodejs-library";
import { ComponentType } from "react";
import classNames from "classnames";

const ResponsiveElementHOC = (TreeElement) => {
  const WrappedElement = (props) => {
    const { id, responsive } = props;

    let wrappedElement = <TreeElement {...props} />;
    if (!!responsive && Object.keys(responsive).length > 0) {
      const { small, medium, large, attributes = {} } = responsive;
      wrappedElement = (
        <Col
          {...attributes}
          data-id={id}
          xs={!!small ? small : 12}
          md={!!medium ? medium : 12}
          lg={!!large ? large : 12}
        >
          {wrappedElement}
        </Col>
      );
    }
    return wrappedElement;
  };

  const displayName = `ResponsiveElement(${TreeElement.displayName || TreeElement.name || 'ResponsiveElementHOC'})`;
  WrappedElement.displayName = displayName;

  return WrappedElement;
};

const CMSTemplateLeaf = ResponsiveElementHOC(CMSTemplate);

const RemoteLeaf = ResponsiveElementHOC(Remote);

const TreeNode = ResponsiveElementHOC(
  (props: Providers.PageLayouts.TreePage.NodeTreePageLayoutType) => {
    const { id, children = [], responsive, attributes = {} } = props;
    const TreeNodeChildren = children.map((child) => (
      <TreeElement {...props} {...child} key={child.id} />
    ));

    return !!responsive && Object.keys(responsive).length > 0 ? (
      <Row {...attributes} className={classNames("g-0", attributes.className)}>
        {TreeNodeChildren}
      </Row>
    ) : (
      <div {...attributes} data-id={id}>
        {TreeNodeChildren}
      </div>
    );
  }
);

const TREE_ELEMENTS_MAP = {
  [Providers.PageLayouts.TreePage.TREE_PAGE_LAYOUT_ELEMENT_TYPES.NODE]:
    TreeNode,
  [Providers.PageLayouts.TreePage.TREE_PAGE_LAYOUT_ELEMENT_TYPES.REMOTE]:
    RemoteLeaf,
  [Providers.PageLayouts.TreePage.TREE_PAGE_LAYOUT_ELEMENT_TYPES.CMS_TEMPLATE]:
    CMSTemplateLeaf,
};

const NOT_FOUND_ELEMENT = ({ id, type }) => (
  <div>{`Error: The Element with ID '${id}' and type '${type}' is not handle!`}</div>
);

function TreeElement(
  props: Providers.PageLayouts.TreePage.ElementTreePageLayoutType
) {
  const TreeElement: ComponentType<Providers.PageLayouts.TreePage.ElementTreePageLayoutType> =
    props.type in TREE_ELEMENTS_MAP
      ? TREE_ELEMENTS_MAP[props.type]
      : NOT_FOUND_ELEMENT;
  return <TreeElement {...props} />;
}

export default function TreePageLayout(
  props: Providers.PageLayouts.TreePage.TreePageLayoutType
) {
  const { placements, ...otherProps } = props;
  const treeElementProps = {
    ...otherProps,
    ...placements,
  } as Providers.PageLayouts.TreePage.ElementTreePageLayoutType;
  return (
    <Container fluid="true">
      <Row className={"g-0"}>
        <TreeElement {...treeElementProps} />
      </Row>
    </Container>
  );
}
