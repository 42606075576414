import React from 'react';
import { Helpers } from "@tpd-web-common-libs/nodejs-library";
import Script from "next/script";
import ENV_CONFIG from "../../../../config/envConfig/esm";
import { useEffect, useState } from "react";

export function accessiwayInit() {
  if (Helpers.EnvironmentHelper.isClientSide()) {
    (window as any).acsbJS.init({
      statementLink: "",
      footerHtml:
        '<a href="https://www.accessiway.com/" target="_blank" rel="noreferer, ,noopener">AccessiWay. The Web Accessibility Solution</a>',
      hideMobile: true,
      hideTrigger: true,
      language: "it",
      position: "left",
      leadColor: "#0069fb",
      triggerColor: "#004fce",
      triggerRadius: "50%",
      triggerPositionX: "left",
      triggerPositionY: "bottom",
      triggerIcon: "people",
      triggerSize: "medium",
      triggerOffsetX: 20,
      triggerOffsetY: 20,
      mobile: {
        triggerSize: "medium",
        triggerPositionX: "left",
        triggerPositionY: "bottom",
        triggerOffsetX: 10,
        triggerOffsetY: 10,
        triggerRadius: "50%",
      },
    });
  }
}

export function AccessiwayScript() {
  const [isClient, setIsClient] = useState(false)
 
  useEffect(() => {
    setIsClient(true)
  }, [])

  return (
    isClient && ENV_CONFIG.ENV_NAME === "production" && (
      <div className="accessiway-script">
        <Script
          defer
          suppressHydrationWarning
          src="https://acsbapp.com/apps/app/dist/js/app.js"
          id="accessiway"
          strategy="lazyOnload"
          onLoad={accessiwayInit}
        />
      </div>
    )
  );
}
