import React from 'react';
import styled from "@emotion/styled";
import { CommonMFE, Styles } from "@tpd-web-common-libs/nodejs-library";
import { useDispatch, useSelector } from "react-redux";
import {
  editorSelector,
  updateIsEditMode,
  updateTouchpoint,
} from "../../store/slices/editor.slice";

const ContainerEditModeButtons = styled.div`
  position: fixed;
  bottom: 10px;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000000;
`;

const EditModeButtons = styled.div`
  margin: 0 auto;
  width: 240px;
`;

const ContainerEditModeToggle = styled.div`
  display: inline-block;
  width: 100px;
  text-align: center;

  padding: 5px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-weight: bold;

  &.edit-mode {
    background-color: #9a14ff;
  }

  &.preview-mode {
    background-color: #2bb10d;
  }
`;

const ContainerTouchpointToggle = styled.div`
  display: inline-block;
  width: 100px;
  text-align: center;

  padding: 5px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-weight: bold;
  background-color: ${Styles.Themes.UnipolColors.blueFive};
  ${Styles.TouchpointUtils.touchpointThemeOnly(
    (touchpoint: any) => `
    background-color: ${touchpoint.darkTertiaryColor};
  `
  )}
`;

export default function EditModeToggle() {
  const { isEditMode, touchpoint } = useSelector(editorSelector);
  const dispatch = useDispatch();
  const toggleIsEditMode = () => {
    dispatch(updateIsEditMode(!isEditMode));
  };

  const toggleUpdateTouchpoint = () => {
    const newTouchpoint = {
      [CommonMFE.TOUCHPOINTS.UNIPOLSAI]: CommonMFE.TOUCHPOINTS.BIMVITA,
      [CommonMFE.TOUCHPOINTS.BIMVITA]: CommonMFE.TOUCHPOINTS.WHITELABEL,
      [CommonMFE.TOUCHPOINTS.WHITELABEL]: CommonMFE.TOUCHPOINTS.UNIPOLSAI,
    }[touchpoint];

    dispatch(updateTouchpoint(newTouchpoint));
  };

  return (
    <ContainerEditModeButtons
      className={CommonMFE.getTouchpointThemeClass(touchpoint)}
    >
      <EditModeButtons>
        <ContainerEditModeToggle
          className={isEditMode ? "edit-mode" : "preview-mode"}
          onClick={toggleIsEditMode}
        >
          {isEditMode ? "Edit Mode" : "Preview Mode"}
        </ContainerEditModeToggle>
        <ContainerTouchpointToggle onClick={toggleUpdateTouchpoint}>
          {touchpoint}
        </ContainerTouchpointToggle>
      </EditModeButtons>
    </ContainerEditModeButtons>
  );
}
