import React from "react";
import { NextRemoteHelper } from "../../../../helpers/nextRemote.helper";
import {
  ReactMFE,
  CommonMFE,
  Providers,
} from "@tpd-web-common-libs/nodejs-library";
import { RemoteErrorMessage } from "./RemoteErrorMessage";

type TRemoteHoc = {
  componentProps: CommonMFE.Types.TProps | null | undefined;
  attributes?: any;
};

function RemoteHOC(Component) {
  const mapping = {
    'tpdHeaderRemote': 'tpdHeader',
    'tpdFooterRemote': 'tpdFooter'
  };
  return (props: TRemoteHoc) => {
    const { componentProps, attributes = {} } = props;
    try {
      if (componentProps) {
        if (!("errorMessage" in componentProps)) {
          if (mapping[componentProps.__mfeName__]) {
            componentProps.__mfeName__ = mapping[componentProps.__mfeName__];
          }
          const remoteService = Providers.RemoteService.getInstance();
          if (remoteService.isAvailableRemote(componentProps.__mfeName__)) {
            const allComponentProps = NextRemoteHelper.getClientSideRemoteProps(
              {
                ...componentProps,
                attributes,
              }
            );

            return <Component {...allComponentProps} />;
          } else {
            throw new Error(
              `It is not available ${componentProps.__mfeName__}`
            );
          }
        } else {
          throw new Error(componentProps.errorMessage);
        }
      } else {
        throw new Error("componentProps is null or undefined");
      }
    } catch (error) {
      return <RemoteErrorMessage errorMessage={error} />;
    }
  };
}

export default RemoteHOC(ReactMFE.Remote);
