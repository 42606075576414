import React from 'react';
import { Helpers, Providers } from "@tpd-web-common-libs/nodejs-library";
import { ReactNode } from "react";
import { CMSProxyLayoutHOC } from "./__shared__/CMSProxyLayout.hoc";

export type TSingleColumnLayoutProps = {
  CMSPlacements: {
    [key: string]: ReactNode; // "slot-1": <div>Slot 1</div>
  };
} & Providers.PageLayouts.PageLayoutType;

function Layout({ CMSPlacements }: TSingleColumnLayoutProps) {
  const getCMSPlacements = Helpers.getNormalizedProps(CMSPlacements);
  const sortedSlotKeys = Object.keys(CMSPlacements)
    .filter((key) => key.match(/^Slot/))
    .sort((a, b) => {
      return parseFloat(a.split("Slot")[1]) - parseFloat(b.split("Slot")[1]);
    });
  return (
    <div className="single-column-layout">
      {sortedSlotKeys.map((key) => (
        <div
          key={`single-column-layout-${key}`}
          className="single-column-layout__item"
        >
          {getCMSPlacements(key)}
        </div>
      ))}
    </div>
  );
}

export const SingleColumnLayout = CMSProxyLayoutHOC(Layout);
