import styled from "@emotion/styled";

export const CustomLayoutContainerDiv = styled.div`
  .g-section,
  .g-section>*,
  .g-section>*: before,
  .g-section>*: after,
  .g-row>*,
  .g-row>*: before,
  .g-row>*: after,
  .g-col>*,
  .g-col>*: before,
  .g-col>*: after,
  .g-container>*,
  .g-container>*: before,
  .g-container>*:after {
    list-style: none;
    margin: 0;
    padding: 0;
    border: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .g-row {
    position: relative;
    display: block;
    text-align: left;
  }
  .g-col {
    display: inline-block;
    width: 100%;
    vertical-align: top;
  }
  .g-row:after {
    content: " ";
    display: table;
    width: 100%;
  }
  .g-row-content {
    max-width: 1000px;
  }
  .g-row-gap {
    padding-top: 3rem;
  }
  .g-row-center {
    margin: 0 auto;
  }
  .g-section-content {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 10px;
    padding-right: 10px;
  }
  .g-col-whole {
    width: 100%;
  }
  .g-col-0-of-12 {
    width: 0%;
  }
  .g-col-1-of-12 {
    width: 8.333333333333334%;
  }
  .g-col-2-of-12,
  .g-col-1-of-6 {
    width: 16.666666666666668%;
  }
  .g-col-3-of-12,
  .g-col-1-of-4 {
    width: 25%;
  }
  .g-col-4-of-12,
  .g-col-1-of-3 {
    width: 33.333333333333336%;
  }
  .g-col-5-of-12 {
    width: 41.666666666666664%;
  }
  .g-col-6-of-12,
  .g-col-1-of-2 {
    width: 50%;
  }
  .g-col-7-of-12 {
    width: 58.333333333333336%;
  }
  .g-col-8-of-12,
  .g-col-2-of-3 {
    width: 66.66666666666667%;
  }
  .g-col-9-of-12,
  .g-col-3-of-4 {
    width: 75%;
  }
  .g-col-10-of-12,
  .g-col-5-of-6 {
    width: 83.33333333333333%;
  }
  .g-col-11-of-12 {
    width: 91.66666666666667%;
  }
  .g-col-12-of-12,
  .g-col-1-of-1 {
    width: 100%;
  }
  @media (max-width: 435px) {
    .g-col.g-col-hidden {
      display: none;
    }
  }
  @media (min-width: 436px) {
    .g-col-a-whole {
      width: 100%;
    }
    .g-col-a-1-of-12 {
      width: 8.333333333333334%;
    }
    .g-col-a-2-of-12,
    .g-col-a-1-of-6 {
      width: 16.666666666666668%;
    }
    .g-col-a-3-of-12,
    .g-col-a-1-of-4 {
      width: 25%;
    }
    .g-col-a-4-of-12,
    .g-col-a-1-of-3 {
      width: 33.333333333333336%;
    }
    .g-col-a-5-of-12 {
      width: 41.666666666666664%;
    }
    .g-col-a-6-of-12,
    .g-col-a-1-of-2 {
      width: 50%;
    }
    .g-col-a-7-of-12 {
      width: 58.333333333333336%;
    }
    .g-col-a-8-of-12,
    .g-col-a-2-of-3 {
      width: 66.66666666666667%;
    }
    .g-col-a-9-of-12,
    .g-col-a-3-of-4 {
      width: 75%;
    }
    .g-col-a-10-of-12,
    .g-col-a-5-of-6 {
      width: 83.33333333333333%;
    }
    .g-col-a-11-of-12 {
      width: 91.66666666666667%;
    }
    .g-col-a-12-of-12,
    .g-col-a-1-of-1 {
      width: 100%;
    }
  }
  @media (min-width: 436px) and(max-width: 600px) {
    .g-col.g-col-a-hidden {
      display: none;
    }
  }
  @media (min-width: 601px) {
    .g-col-b-whole {
      width: 100%;
    }
    .g-col-b-1-of-12 {
      width: 8.333333333333334%;
    }
    .g-col-b-2-of-12,
    .g-col-b-1-of-6 {
      width: 16.666666666666668%;
    }
    .g-col-b-3-of-12,
    .g-col-b-1-of-4 {
      width: 25%;
    }
    .g-col-b-4-of-12,
    .g-col-b-1-of-3 {
      width: 33.333333333333336%;
    }
    .g-col-b-5-of-12 {
      width: 41.666666666666664%;
    }
    .g-col-b-6-of-12,
    .g-col-b-1-of-2 {
      width: 50%;
    }
    .g-col-b-7-of-12 {
      width: 58.333333333333336%;
    }
    .g-col-b-8-of-12,
    .g-col-b-2-of-3 {
      width: 66.66666666666667%;
    }
    .g-col-b-9-of-12,
    .g-col-b-3-of-4 {
      width: 75%;
    }
    .g-col-b-10-of-12,
    .g-col-b-5-of-6 {
      width: 83.33333333333333%;
    }
    .g-col-b-11-of-12 {
      width: 91.66666666666667%;
    }
    .g-col-b-12-of-12,
    .g-col-b-1-of-1 {
      width: 100%;
    }
  }
  @media (min-width: 601px) and(max-width: 800px) {
    .g-col.g-col-b-hidden {
      display: none;
    }
  }
  @media (min-width: 801px) {
    .g-col-c-whole {
      width: 100%;
    }
    .g-col-c-1-of-12 {
      width: 8.333333333333334%;
    }
    .g-col-c-2-of-12,
    .g-col-c-1-of-6 {
      width: 16.666666666666668%;
    }
    .g-col-c-3-of-12,
    .g-col-c-1-of-4 {
      width: 25%;
    }
    .g-col-c-4-of-12,
    .g-col-c-1-of-3 {
      width: 33.333333333333336%;
    }
    .g-col-c-5-of-12 {
      width: 41.666666666666664%;
    }
    .g-col-c-6-of-12,
    .g-col-c-1-of-2 {
      width: 50%;
    }
    .g-col-c-7-of-12 {
      width: 58.333333333333336%;
    }
    .g-col-c-8-of-12,
    .g-col-c-2-of-3 {
      width: 66.66666666666667%;
    }
    .g-col-c-9-of-12,
    .g-col-c-3-of-4 {
      width: 75%;
    }
    .g-col-c-10-of-12,
    .g-col-c-5-of-6 {
      width: 83.33333333333333%;
    }
    .g-col-c-11-of-12 {
      width: 91.66666666666667%;
    }
    .g-col-c-12-of-12,
    .g-col-c-1-of-1 {
      width: 100%;
    }
  }
  @media (min-width: 801px) and(max-width: 896px) {
    .g-col.g-col-c-hidden {
      display: none;
    }
  }
  @media (min-width: 897px) {
    .g-col-d-whole {
      width: 100%;
    }
    .g-col-d-1-of-12 {
      width: 8.333333333333334%;
    }
    .g-col-d-2-of-12,
    .g-col-d-1-of-6 {
      width: 16.666666666666668%;
    }
    .g-col-d-3-of-12,
    .g-col-d-1-of-4 {
      width: 25%;
    }
    .g-col-d-4-of-12,
    .g-col-d-1-of-3 {
      width: 33.333333333333336%;
    }
    .g-col-d-5-of-12 {
      width: 41.666666666666664%;
    }
    .g-col-d-6-of-12,
    .g-col-d-1-of-2 {
      width: 50%;
    }
    .g-col-d-7-of-12 {
      width: 58.333333333333336%;
    }
    .g-col-d-8-of-12,
    .g-col-d-2-of-3 {
      width: 66.66666666666667%;
    }
    .g-col-d-9-of-12,
    .g-col-d-3-of-4 {
      width: 75%;
    }
    .g-col-d-10-of-12,
    .g-col-d-5-of-6 {
      width: 83.33333333333333%;
    }
    .g-col-d-11-of-12 {
      width: 91.66666666666667%;
    }
    .g-col-d-12-of-12,
    .g-col-d-1-of-1 {
      width: 100%;
    }
  }
  @media (min-width: 897px) and(max-width: 1024px) {
    .g-col.g-col-d-hidden {
      display: none;
    }
  }
  @media (min-width: 1025px) {
    .g-col-e-whole {
      width: 100%;
    }
    .g-col-e-1-of-12 {
      width: 8.333333333333334%;
    }
    .g-col-e-2-of-12,
    .g-col-e-1-of-6 {
      width: 16.666666666666668%;
    }
    .g-col-e-3-of-12,
    .g-col-e-1-of-4 {
      width: 25%;
    }
    .g-col-e-4-of-12,
    .g-col-e-1-of-3 {
      width: 33.333333333333336%;
    }
    .g-col-e-5-of-12 {
      width: 41.666666666666664%;
    }
    .g-col-e-6-of-12,
    .g-col-e-1-of-2 {
      width: 50%;
    }
    .g-col-e-7-of-12 {
      width: 58.333333333333336%;
    }
    .g-col-e-8-of-12,
    .g-col-e-2-of-3 {
      width: 66.66666666666667%;
    }
    .g-col-e-9-of-12,
    .g-col-e-3-of-4 {
      width: 75%;
    }
    .g-col-e-10-of-12,
    .g-col-e-5-of-6 {
      width: 83.33333333333333%;
    }
    .g-col-e-11-of-12 {
      width: 91.66666666666667%;
    }
    .g-col-e-12-of-12,
    .g-col-e-1-of-1 {
      width: 100%;
    }
  }
  @media (min-width: 1025px) and(max-width: 1280px) {
    .g-col.g-col-e-hidden {
      display: none;
    }
  }
  @media (min-width: 1281px) {
    .g-col.g-col-f-hidden {
      display: none;
    }
    .g-col-f-whole {
      width: 100%;
    }
    .g-col-f-1-of-12 {
      width: 8.333333333333334%;
    }
    .g-col-f-2-of-12,
    .g-col-f-1-of-6 {
      width: 16.666666666666668%;
    }
    .g-col-f-3-of-12,
    .g-col-f-1-of-4 {
      width: 25%;
    }
    .g-col-f-4-of-12,
    .g-col-f-1-of-3 {
      width: 33.333333333333336%;
    }
    .g-col-f-5-of-12 {
      width: 41.666666666666664%;
    }
    .g-col-f-6-of-12,
    .g-col-f-1-of-2 {
      width: 50%;
    }
    .g-col-f-7-of-12 {
      width: 58.333333333333336%;
    }
    .g-col-f-8-of-12,
    .g-col-f-2-of-3 {
      width: 66.66666666666667%;
    }
    .g-col-f-9-of-12,
    .g-col-f-3-of-4 {
      width: 75%;
    }
    .g-col-f-10-of-12,
    .g-col-f-5-of-6 {
      width: 83.33333333333333%;
    }
    .g-col-f-11-of-12 {
      width: 91.66666666666667%;
    }
    .g-col-f-12-of-12,
    .g-col-f-1-of-1 {
      width: 100%;
    }
  }
`;
