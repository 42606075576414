/* eslint-disable no-useless-escape */
import React from 'react';
export function UtagConfigScript() {
  return (
    <script
      defer
      className="utag-config-script"
      type={"text/javascript"}
      suppressHydrationWarning
      src={"/NextAssets/scripts/utag-config-script.js"}
    />
  );
}
