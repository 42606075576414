import React from 'react';
import { Providers } from "@tpd-web-common-libs/nodejs-library";

export default function CMSTemplate(
  props: Providers.PageLayouts.TreePage.CMSTemplateTreePageLayoutType
) {
  const {
    componentProps: { id, __cms_template_html__ },
    attributes = {},
  } = props;

  return (
    <div
      data-id={id}
      {...attributes}
      suppressHydrationWarning
      dangerouslySetInnerHTML={{ __html: __cms_template_html__ }}
    />
  );
}
