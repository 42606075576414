import React from 'react';
import { Helpers, Providers } from "@tpd-web-common-libs/nodejs-library";
import { FunctionComponent } from "react";
/** Layout */
import TreePageLayout from "./TreePageLayout";
import { SingleColumnLayout } from "./SingleColumnLayout";
import { ConfiguratoreCasaFormLayout } from "./ConfiguratoreCasaFormLayout";
import { CustomTemplateLayout } from "./CustomLayout";
import dynamic from "next/dynamic";
import LazyHydrate from "react-lazy-hydration";

const Remote = dynamic(() => import("./__shared__/Remote/Remote"));

const NOT_FOUND_LAYOUT = ({
  layout,
  id,
  title,
}: Omit<Providers.PageLayouts.TNextPage, "header" | "footer">) => (
  <div>{`Error: The Layout '${layout}' of page with ID '${id}' and title '${title}' is not handle!`}</div>
);

const LAYOUT_MAP = {
  [Providers.PageLayouts.TreePage.TREE_PAGE_LAYOUT_TYPE]: TreePageLayout, // TODO - DEPRECATED - need to remove
  // TODO - DEPRECATED - need to remove
  [Providers.PageLayouts.CmsPage.Layouts.SINGLE_COLUMN_LAYOUT_TYPE]:
    SingleColumnLayout,
  // TODO - DEPRECATED - need to remove
  [Providers.PageLayouts.CmsPage.Layouts.CONFIGURATORE_CASA_FORM_LAYOUT_TYPE]:
    ConfiguratoreCasaFormLayout,
  [Providers.PageLayouts.CmsPage.Layouts.HP_TEMPLATE_LAYOUT_TYPE]:
    CustomTemplateLayout,
  [Providers.PageLayouts.CmsPage.Layouts.ONECOLUMN_TEMPLATE_LAYOUT_TYPE]:
    CustomTemplateLayout,
  [Providers.PageLayouts.CmsPage.Layouts.CUSTOM_TEMPLATE_LAYOUT_TYPE]:
    CustomTemplateLayout,
  [Providers.PageLayouts.CmsPage.Layouts
    .UTILE_A_SAPERSI_FOGLIA_TEMPLATE_LAYOUT_TYPE]: CustomTemplateLayout,
  [Providers.PageLayouts.CmsPage.Layouts
    .PREVENTIVATORE_AUTO_FORM_TEMPLATE_LAYOUT_TYPE]: CustomTemplateLayout,
  [Providers.PageLayouts.CmsPage.Layouts.QUOTATORI_FORM_TEMPLATE_LAYOUT_TYPE]:
    CustomTemplateLayout,
  [Providers.PageLayouts.CmsPage.Layouts
    .ASSICURAZIONE_AUTO_ADV_TEMPLATE_LAYOUT_TYPE]: CustomTemplateLayout,
};

export default function PageLayout({
  header,
  footer,
  ...props
}: Providers.PageLayouts.TNextPage) {
  const Layout: FunctionComponent<Providers.PageLayouts.TNextPageLayoutType> =
    props.layout in LAYOUT_MAP ? LAYOUT_MAP[props.layout] : NOT_FOUND_LAYOUT;

  const disableHydratation =
    Helpers.EnvironmentHelper.isClientSide() &&
    (navigator.userAgent.includes("Google PageSpeed Insights") ||
      navigator.userAgent.includes("Chrome-Lighthouse"));

  return (
    <>
      {/* <LazyHydrate
        ssrOnly={disableHydratation}
        whenVisible={!disableHydratation}
      > */}
        <Remote
          componentProps={Helpers.RemoteHelper.getComponentPropsBy(
            header,
            props
          )}
        />
      {/* </LazyHydrate> */}
      <Layout {...props} />
      <LazyHydrate
        ssrOnly={disableHydratation}
        whenVisible={!disableHydratation}
      >
        <Remote
          componentProps={Helpers.RemoteHelper.getComponentPropsBy(
            footer,
            props
          )}
        />
      </LazyHydrate>
    </>
  );
}
