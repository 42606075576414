import React from 'react';
import { Helpers, Providers } from "@tpd-web-common-libs/nodejs-library";
import { CMSProxyLayoutHOC } from "./__shared__/CMSProxyLayout.hoc";
import styled from "@emotion/styled";
import Link from "next/link";

export type TConfiguratoreCasaFormLayoutProps = {
  CMSPlacements: {
    Immagine_con_testo_laterale: any;
    Box_reason_why: any;
  };
} & Providers.PageLayouts.PageLayoutType;

function Layout({ CMSPlacements }: TConfiguratoreCasaFormLayoutProps) {
  const getCMSPlacements = Helpers.getNormalizedProps(CMSPlacements);
  const BoxReasonWhy = getCMSPlacements(
      Providers.PageLayouts.CmsPage.Layouts
          .CONFIGURATORE_CASA_FORM_LAYOUT_PLACEMENT_TYPE.BOX_REASON_WHY
  );

  return (
      <ContainerDiv className="configuratore-casa-form-layout">
        <div className="mh-container">
          <div className="mh-header">
            <div className="mh-logo" />
          </div>
          <div className="mh-banner-top">
            <div className="mh-banner-title">${Helpers.BrandNameHelper.brandName()} Casa&Servizi</div>
          </div>
          <div className="mh-body">
            <div className="mh-steps">
              <div className="mh-title">
                Scopri l&apos;offerta giusta per te in pochi click
              </div>
              <div className="mh-line" />
            </div>
            <div className="mh-wrapper-content">
              <div className="mh-wrapper-left">facsimile</div>
              <div className="mh-wrapper-right">
                <div className="mh-wr-container">{BoxReasonWhy}</div>
              </div>
            </div>
          </div>
          <div className="mh-footer">
            <div className="mh-privacy">
              Iniziativa <strong>Più3</strong>
              valida dal 01/06/2022 al 30/04/2023, per la sottoscrizione di nuovi
              contratti. Sconto del 15% in base alle garanzie scelte, per maggiori
              dettagli&nbsp;
              <Link href="/casa/assicurazione-casa/polizza-casa-servizi">
                <a>clicca qui</a>
              </Link>
              .<br />
              <br />
              <strong>Messaggio pubblicitario.</strong> Prima della sottoscrizione
              leggere il set informativo su
              <Link href="/homepage">
                <a>www.${Helpers.BrandNameHelper.urlName()}.it</a>
              </Link>. Le garanzie sono soggette
              a limitazioni, esclusioni e condizioni di operatività e alcune sono
              prestate solo in abbinamento con altre.
            </div>
            <div className="mh-wrapper-banner">
              <div className="mh-banner-bottom">
                <div className="mh-banner-bottom-elm">privacy</div>
                <div className="mh-banner-bottom-elm">
                  informativa e gestione cookie
                </div>
                <div className="mh-banner-bottom-elm">
                  accesso area riservata ivass 41/2018
                </div>
              </div>
            </div>
            <div className="mh-business">
              <span>${Helpers.BrandNameHelper.brandName()} Assicurazioni S.p.A</span>
              <br />
              Sede Legale: Via Stalingrado, 45 40128 Bologna
              <br /> Telefono: 051 5076111 - Fax: 051 375349 - Mail Pec:
              {Helpers.BrandNameHelper.pec()}@pec.unipol.it
              <br /> Capitale sociale € 2.031.456.338,00 - Registro delle Imprese
              di Bologna
              <br /> C.F. 00818570012 e P.IVA 03740811207
            </div>
          </div>
        </div>
      </ContainerDiv>
  );
}

export const ConfiguratoreCasaFormLayout = CMSProxyLayoutHOC(Layout);

const ContainerDiv = styled.div`
  .mh-container {
  }

  .mh-header .mh-logo {
    background: url(/UnipolSaiThemeDynamicWAR/themes/html/dynamicSpots/assets/images/header/unipolsai_tricolore.png);
    width: 235px;
    height: 75px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .mh-container .mh-header {
    width: 80%;
    margin: 0 auto;
    padding-bottom: 15px;
    padding-top: 15px;
  }

  .mh-container .mh-banner-top {
    padding: 50px 0;
    background-color: rgb(233, 78, 16);
  }

  .mh-container .mh-banner-top .mh-banner-title {
    font-size: 30px;
    font-family: "Unipol Medium";
    color: white;
    width: 80%;
    margin: 0 auto;
    text-transform: uppercase;
  }

  .mh-container .mh-body {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 60px;
  }

  .mh-container .mh-body .mh-steps {
    border-bottom: 1px solid rgb(216, 216, 216);
    margin-bottom: 80px;
  }

  .mh-container .mh-body .mh-steps .mh-title {
    padding: 20px 0;
    font-size: 24px;
    color: rgb(25, 58, 86);
    font-family: "Unipol";
    font-weight: 400;
  }

  .mh-container .mh-body .mh-steps .mh-line {
    width: 30%;
    border-bottom: 5px solid rgb(25, 58, 86);
  }

  .mh-container .mh-body .mh-wrapper-content {
    display: flex;
    justify-content: space-evenly;
  }

  .mh-container .mh-body .mh-wrapper-content .mh-wrapper-left {
    width: 517px;
    background-color: #7c848a;
    display: flex;
    align-items: center;
    font-size: 50px;
    text-transform: uppercase;
    justify-content: center;
    color: white;

    @media only screen and (max-width: 1280px) {
      max-width: 100%;
    }
  }

  .mh-container .mh-body .mh-wrapper-content .mh-wrapper-right {
  }

  .mh-container .mh-footer {
  }

  .mh-container .mh-footer .mh-privacy {
    width: 70%;
    margin: 0 auto;
    color: rgb(25, 58, 86);
    font-family: "Unipol";
    margin-bottom: 60px;
  }

  .mh-container .mh-footer .mh-wrapper-banner .mh-banner-bottom {
    color: white;
    font-size: 16px;
    font-family: "Unipol Medium";
    display: flex;
    flex-direction: row;
    padding: 30px 0;
    width: 80%;
    margin: 0 auto;
    text-transform: uppercase;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .mh-container .mh-footer .mh-wrapper-banner {
    background-color: rgb(25, 58, 86);
    margin-bottom: 60px;
  }

  .mh-container .mh-footer .mh-business {
    color: rgb(155, 155, 155);
    font-family: "Unipol";
    font-size: 16px;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 60px;
  }

  .mh-container .mh-footer .mh-business span {
    font-family: "Unipol Medium";
  }

  .mh-container
  .mh-footer
  .mh-wrapper-banner
  .mh-banner-bottom
  .mh-banner-bottom-elm {
    width: 30%;
    text-align: center;
  }

  .mh-container
  .mh-body
  .mh-wrapper-content
  .mh-wrapper-right
  .mh-wr-container {
    display: flex;
    flex-direction: column;
    max-width: 517px;

    @media only screen and (max-width: 1280px) {
      max-width: 100%;
    }
  }

  .mh-container .mh-footer .mh-preview-app {
    display: flex;
    height: 50px;
    align-items: center;
    background-color: #f0f0f0;
    padding: 0 20px;
    font-family: "Unipol Medium";
    position: fixed;
    text-decoration: underline;
    /* Safari */
    bottom: 0;
    left: 0;
    width: 100%;
  }

  @media only screen and (max-width: 1280px) {
    .mh-container .mh-header {
      margin-bottom: 0px;
      margin-top: 0px;
    }

    .mh-header .mh-logo {
      width: 150px;
      height: 60px;
    }

    .mh-container .mh-body .mh-wrapper-content .mh-wrapper-left {
      height: 500px;
      width: 100%;
      margin-bottom: 20px;
    }

    .mh-container .mh-body .mh-wrapper-content {
      flex-direction: column;
    }
  }

  @media only screen and (max-width: 768px) {
    .mh-container .mh-footer .mh-wrapper-banner .mh-banner-bottom {
      flex-direction: column;
      align-items: center;
      width: 100%;
      row-gap: 20px;
    }

    .mh-container
    .mh-footer
    .mh-wrapper-banner
    .mh-banner-bottom
    .mh-banner-bottom-elm {
      width: 100%;
    }
  }
`;
