import React from 'react';
import { CMSPlacementsMap } from "./CMSProxyLayout/CMSPlacementsMap";

export const CMSProxyLayoutHOC = (Layout) => {
  const WrappedLayout = (props) => {
    const layoutProps = {
      ...props,
      CMSPlacements: CMSPlacementsMap(props),
    };
    return <Layout {...layoutProps} />;
  };

  const displayName = `CMSProxyLayout(${Layout.displayName || Layout.name || 'CMSProxyLayoutHOC'})`;
  WrappedLayout.displayName = displayName;

  return WrappedLayout;
};

