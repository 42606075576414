import React from 'react';
import {
  CommonMFE,
  Helpers,
  Providers,
} from "@tpd-web-common-libs/nodejs-library";
import { CMSProxyLayoutHOC } from "./__shared__/CMSProxyLayout.hoc";
/** Bootstrap */
import { Container, Row, Col } from "react-bootstrap";
import { CustomLayoutContainerDiv } from "./CustomLayout.style";

type BootstrapType = {
  children: React.ReactNode;
  container: Providers.PageLayouts.CmsPage.TLayoutDataContainer;
  className: string;
  id?: string;
};
/** It renders a children under Bootstrap style */
function Column({
  children,
  container,
  className,
}: BootstrapType): JSX.Element {
  const breakPoints = {
    xs: Number((container.responsive && container.responsive.small) || "12"),
    md: Number((container.responsive && container.responsive.medium) || "12"),
    lg: Number((container.responsive && container.responsive.large) || "12"),
  };
  return (
    <Col
      className={className}
      xs={breakPoints.xs}
      md={breakPoints.md}
      lg={breakPoints.lg}
    >
      {children}
    </Col>
  );
}

function Layout({
  id: pageID,
  CMSPlacements,
  layoutData,
}: Providers.PageLayouts.CmsPage.TCustomLayoutProps) {
  const getCMSPlacements = Helpers.getNormalizedProps(CMSPlacements);
  const layoutClass = Helpers.formatRawClasses(layoutData && layoutData.name);
  return (
    <CustomLayoutContainerDiv>
      <Container className={`${layoutClass} p-0`} fluid>
        {layoutData &&
          layoutData.rows &&
          layoutData.rows.map((row, rowIndex) => {
            const rowID = CommonMFE.generateID(pageID, `row`, rowIndex);
            return (
              <Row key={rowID} id={rowID} className="component-control g-0">
                {row.containers.map((container, containerIndex) => {
                  const containerID = CommonMFE.generateID(
                    rowID,
                    `container`,
                    containerIndex
                  );
                  const containerClasses = Helpers.formatRawClasses(
                    container.classes
                  );
                  const placements = getCMSPlacements(container.slotName);
                  const placementArray = Array.isArray(placements)
                    ? placements
                    : [placements];
                  return (
                    <>
                      {placementArray.map((placement, placementIndex) => {
                        const columnID = CommonMFE.generateID(
                          containerID,
                          `column`,
                          placementIndex
                        );
                        return (
                          <Column
                            className={containerClasses}
                            key={columnID}
                            id={columnID}
                            container={container}
                          >
                            {placement}
                          </Column>
                        );
                      })}
                    </>
                  );
                })}
              </Row>
            );
          })}
      </Container>
    </CustomLayoutContainerDiv>
  );
}

export const CustomTemplateLayout = CMSProxyLayoutHOC(Layout);
