import React from 'react';
import { AlertMessage } from "./AlertMessage";
import { Helpers } from "@tpd-web-common-libs/nodejs-library";

export const RemoteErrorMessage = (props) => {
  const { errorMessage } = props;

  return (
    <div
      data-error="remote-error"
      style={{
        display: Helpers.EnvironmentHelper.isLocalEnvironment()
          ? "inherit"
          : "none",
      }}
    >
      <AlertMessage
        message={
          "Trovata configurazione non valida. Contattare l'amministratore"
        }
      >
        {errorMessage && (
          <div
            style={{
              display: Helpers.EnvironmentHelper.isLocalEnvironment()
                ? "inherit"
                : "none",
            }}
            suppressHydrationWarning
            dangerouslySetInnerHTML={{
              __html: String(errorMessage).replaceAll("\n", "<br/>"),
            }}
          />
        )}
      </AlertMessage>
    </div>
  );
};
