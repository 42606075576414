import React from 'react';
import styled from "@emotion/styled";

const AlertContainerDiv = styled.div`
  box-shadow: #eee 0 2px 10px;
  margin: 20px auto;
  padding: 0;
  border: 1px solid #ccc;
  background-color: #fff;
`;

const AlertContent = styled.div`
  padding: 20px;
  overflow: auto;
  position: relative;
`;

const AlertImage = styled.img`
  left: 30px;
  position: absolute;
  top: 20px;
`;

const AlertWrapperMessage = styled.div`
  margin: 0 10px 0 85px;
`;

const AlertHeading = styled.div`
  font-size: calc(1.375rem + 1.5vw);
  font-family: Unipol;
`;

const AlertText = styled.p`
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: Unipol;
`;

export const AlertMessage = (props) => {
  const { message, children } = props;
  return (
    <AlertContainerDiv>
      <AlertContent>
        <AlertImage
          src="/NextAssets/icons/iconWarningLarge.gif"
          alt="Warning"
          title="Warning"
        />
        <AlertWrapperMessage>
          <AlertHeading>Avvertenza</AlertHeading>
          <AlertText>{message}</AlertText>
          {children}
        </AlertWrapperMessage>
      </AlertContent>
    </AlertContainerDiv>
  );
};
